import axios from 'axios'
import {Notification, Message} from 'element-ui'
import errorCode from '@/utils/errorCode'
import {setAppLanguage} from "@/utils/sist";


//todo 显示消息处理
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 10000
})
// request拦截器
service.interceptors.request.use(config => {
  if (config.method == 'post') {
    config.data['sitetype'] = 'NEd5n92EMIpyyBslaNqsRgE';
    let lang = sessionStorage.getItem("lang");
    config.data['lang'] = lang;
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    if (code === 500) {
      //Message({
      //  message: msg,
      //  type: 'error'
      //})
      return Promise.reject(new Error(msg))
    } else if (code == 199) {
      //Message({
      //  message: "查询结果为空!",
      //  type: 'info'
      //})
      return res.data
    } else if (code !== 200) {
      Notification.error({
        title: msg
      })
      return Promise.reject('error')
    } else {
      return res.data
    }
  },
  error => {
    console.log('err' + error)
    let {message} = error;
    if (message === "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    //Message({
    //  message: message,
    //  type: 'error',
    //  duration: 5 * 1000
    //})
    return Promise.reject(error)
  }
)

export default service
