<template>
  <div id="app">
    <div v-show="iftop" class="totop flexCenter" @click="backTop">
      <i class="el-icon-top" style="color: #409eff"></i>
    </div>
    <div class="headers">
      <headers v-show="width" />
      <MobileHerder v-show="!width"/>
    </div>
    <el-row>
      <el-col>
        <router-view/>
      </el-col>
    </el-row>
    <footers/>
  </div>
</template>
<script>
import headers from './views/header/index'
import MobileHerder from './views/header/mobile_herder'
import footers from './views/footer/index'
import {setAppLanguage,setThis} from "@/utils/sist";

export default {
  name: 'App',
  components: {
    headers,
    footers,
    MobileHerder
  },
  data() {
    return {
      iftop: false,
      timer : true,
      lang:'cn',
      width: document.documentElement.clientWidth >= 992
    }
  },
  created() {
    setThis(this)
    setAppLanguage(this)
  },
  mounted() { //监听scroll方法
    window.addEventListener('scroll', this.handleScroll)

    const that = this
    window.onresize = () => {
      return (() => {
        //这里写要操作的函数
        window.screenWidth = document.body.clientWidth
        that.width = window.screenWidth>992
      })()
    }
  },
  beforeDestroy() { //及时释放
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      this.iftop = window.pageYOffset > 400
    },
    backTop() {
      window.scrollTo({top: 0, behavior: 'smooth',})
    }
  }
}
</script>
<style>

* {
  margin: 0;
  padding: 0;
  font-family: "微软雅黑" !important;
}

.grid-content {
  height: 1px;
  width: 100%;
}
</style>
<style scoped>
.headers {
  width: 100%;
  z-index: 999;
  position: absolute;
  /*top: 0;*/
  /*left: 0;*/
}

.totop {
  text-align: center;
  position: fixed;
  bottom: 100px;
  right: 40px;
  width: 40px;
  height: 40px;
  font-size: 24px;
  line-height: 40px;
  font-weight: 600;
  color: #1989fa;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 6px rgba(0, 0, 0, .12);
  cursor: pointer;
  z-index: 5;
}
</style>
