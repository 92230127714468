/**
 * @author: Clay
 * @date: 2022/1/14 13:26
 * @description：index
 * @email: clay@hchyun.com
 */
import request from '@/utils/request'

// 获取统计数据
export function getConfig(configKey) {
  return request({
    url: '/web/config/'+configKey,
    method: 'get',
  })
}
// 获取所有导航
export function getNavigation(data) {
  return request({
    url: '/web/getnavall',
    method: 'post',
    data:data
  })
}

// 获取节点信息(通用接口)
export function getNode(data) {
  return request({
    url: '/web/node',
    method: 'post',
    data:data
  })
}
export function getNodeNav(data) {
  return request({
    url: '/web/nodeNav',
    method: 'post',
    data:data
  })
}
// 获取文章列表
export function getArticleList(data) {
  return request({
    url: '/web/articlelist',
    method: 'post',
    data:data
  })
}


// 获取面包屑
export function crumbs(id) {
  return request({
    url: '/web/crumbs',
    method: 'post',
    data:{
      id:id
    }
  })
}


// 获取banner图
export function banner(type) {
  return request({
    url: '/web/banner',
    method: 'post',
    data:{
      type:type
    }
  })
}

// 获取文章详情
export function article(id) {
  return request({
    url: '/web/article',
    method: 'post',
    data:{
      id:id
    }
  })
}

// 获取文章详情
export function info(id) {
  return request({
    url: '/web/info',
    method: 'post',
    data:{
      id:id
    }
  })
}
