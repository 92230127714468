<template>
  <div id="mobile_herder" class="mobile_herder">
    <div class="bottom" id="bottom">
      <div @click="drawer = true">
        <i class="el-icon-s-fold"></i>
      </div>
      <div class="img">
        <img src="@/assets/header/sy_logo1@2x.png" alt="">
      </div>
      <!--    <div class="img">-->
      <!--      <img src="@/assets/header/sy_logo2@2x.png" alt="">-->
      <!--    </div>-->
      <div class="switchLang" @click="switchLang">{{ $t('message.switch') }}</div>
    </div>
    <el-drawer
      size="60%"
      :append-to-body="true"
      :withHeader="false"
      :visible.sync="drawer"
      :direction="direction"
    >
      <!--    <div class="banner">导航</div>-->
      <el-menu
        default-active="2"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose">
        <el-menu-item index="1" @click="home">
          <span slot="title">{{ $t('message.home') }}</span>
        </el-menu-item>
        <el-submenu v-for="(menu,menuIndex) in menuData" :index="menuIndex+2" :key="menuIndex">
          <template slot="title">
            <span v-if="$i18n.locale  === 'cn'">{{ menu.title }}</span>
            <span v-if="$i18n.locale  === 'en'">{{ menu.englishTitle }}</span>
          </template>

          <span v-for="(item,itemIndex) in menu.children" :key="itemIndex">
            <el-submenu :index="menuIndex +'-'+itemIndex" v-if="item.children.length > 0 && item.displayType != 4">
              <template slot="title" v-if="item.children.length > 0 &&$i18n.locale  === 'cn' ">{{
                  item.title
                }}</template>
              <template slot="title" v-if="item.children.length > 0 && $i18n.locale  === 'en'">{{
                  item.englishTitle
                }}</template>
              <el-menu-item v-for="(chile ,chileIndex) in item.children" :key="chileIndex"
                            v-if="chile.displayType != 4"
                            :index="menuIndex + '-' +itemIndex + '-' +chileIndex" @click="clickMenu(chile,item)">
                <span v-if="$i18n.locale  === 'cn'&&(chile.displayType == 1 || chile.displayType == 3)">{{
                    chile.title
                  }}</span>
                <span v-if="$i18n.locale  === 'en'&&(chile.displayType == 1 || chile.displayType == 2)">{{
                    chile.englishTitle
                  }}</span>
              </el-menu-item>
            </el-submenu>
            <el-menu-item v-if="!item.children.length>0 && (item.displayType != 4)" :key="itemIndex"
                          :index="menuIndex +'-'+itemIndex"
                          @click="clickSecondMenu(item)">
              <span v-if="$i18n.locale  === 'cn'&&(item.displayType == 1 || item.displayType == 3)">{{
                  item.title
                }}</span>
              <span v-if="$i18n.locale  === 'en'&&(item.displayType == 1 || item.displayType == 2)">{{
                  item.englishTitle
                }}</span>
            </el-menu-item>
          </span>
        </el-submenu>
      </el-menu>
    </el-drawer>
  </div>

</template>

<script>
import {getNavigation, getNode} from "@/api";

import {handleTree} from "@/utils/ebts";

export default {
  name: "mobile_herder",
  data() {
    return {
      drawer: false,
      direction: 'ltr',
      mobile: document.documentElement.clientWidth <= 992,
      menuData: [],
    };
  },
  created() {
    if (this.mobile) {
      getNavigation({}).then(res => {
        this.menuData = res.data
        sessionStorage.setItem("menu", JSON.stringify(res.data))
      })
    }
  },
  methods: {
    home() {
      this.$router.push("/home?lang=" + sessionStorage.getItem("lang"))
    },
    // 二级菜单点击
    clickSecondMenu(menu) {
      this.drawer = false
      this.$router.push("/mobile/" + menu.encodeId + "?type=" + menu.type + "&lang=" + sessionStorage.getItem("lang"));

    },
    //三级菜单点击
    clickMenu(menu, parent) {
      console.log("三级菜单点击")
      this.drawer = false
      this.$router.push("/mobile/" + parent.encodeId + "/" + menu.encodeId + "?type=" + menu.type + "&lang=" + sessionStorage.getItem("lang"));
    },
    switchLang() {
      let lang = ''
      if (this.$i18n.locale === 'en') {
        lang = 'cn'
      } else {
        lang = 'en'
      }
      sessionStorage.setItem("lang", lang)
      let fullPath = this.$route.fullPath
      window.location.replace(fullPath.substr(0, fullPath.length - 2) + lang)
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  }
}
</script>
<style>
.el-drawer__wrapper {
  z-index: 9999 !important;
}

/*#el-drawer__title{*/
/*  display: none !important;*/
/*}*/
</style>

<style scoped lang="scss">
.banner {
  text-align: center;
  height: 4rem;
  font-size: 3rem;
  margin-top: 3rem;
}

.mobile_herder {

  .top {
    padding-left: 2rem;
    height: 4rem;
    background-color: #1956BC;
    line-height: 4rem;
    font-size: 1.6rem;
    color: #ffffff;
  }

  .bottom {
    background-color: #1956BC;
    padding-left: 2rem;
    font-size: 5rem;
    height: 6.3rem;
    line-height: 6rem;
    cursor: pointer;
    clear: left;

    .el-icon-s-fold {
      width: 6rem;
      height: 6rem;
      color: white;
    }

    div {
      float: left;
    }

    .switchLang {
      float: right;
      font-size: 2rem;
      color: #ffffff;
      margin-right: 3rem;
    }

    img {
      height: 3rem;
      line-height: 6rem;
    }

    .img {
      margin-left: 3rem;
    }

    //width: 100%;
  }
}
</style>
