//cn.js

import zhLocale from 'element-ui/lib/locale/lang/zh-CN'


//{{ $t('message.news_ceter') }}
const cn = {
  message: {
    'student': '学生',
    'staff': '教职工',
    'alumni': '校友',
    'examine': '考生',
    'services_portal': '服务门户',
    'switch': 'English',
    'home': '学院首页',
    'news_ceter': '新闻中心',
    'more': '更多',
    'research': '科学研究',
    'academic': '学术交流',
    'party': '党群工作',
    'party_education': '党史教育',
    'teaching_work': '教学工作',

    'data_information': '数据中的信息',
    'notice': '通知公告',
    'scientific': '科学研究',
    'undergraduate_education': '本科教育',
    'postgraduate_education': '研究生教育',
    'employees': '职工人数',
    'number_students': '在校学生人数',
    'graduate_tutor': '研究生导师',
    'research_team': '科研团队',
    'high_level_talents': '省部级以上高层次人才',
    'major_projects': '国家级重大重点项目',
    'contact_us': '联系我们',
    'person': '人',
    'pics': '个',
    'adress': '犀浦校区地址:中国四川省成都市郫都区犀安路 999 号西南交通大学信息科学与技术学院',
    'code': '邮政编码:611756',
    'tel': '电话:+86 28 66366709(行政)',
    'copyright': '版权所有◎2015西南交通大学信息科学与技术学院. All rights reserved.意见反馈',
    'chinese_site': '西南交通大学(中文)',
    'english_site': '西南交通大学(ENGLISH)',
    'affairs_office': '西南交通大学国际合作与交流处',
    'scholarship': '国家留学基金管理委员会',
    'foreign': '中华人民共和国外交部',
    'education': '中华人民共和国教育部门户网站',
    'ieee': '电气和电子工程师协会(IEEE)',
    'search': '搜索',
    'placeholder': '请输入内容',
  },
  ...zhLocale
}

export default cn
