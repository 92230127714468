import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  // {
  //  path: '/test',
  //  name: 'index',
  //  component: () => import(/* webpackChunkName: "about" */ '../views/test.vue')
  // },
  {
    path: '/home',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/anchor')
  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: () => import(/* webpackChunkName: "about" */ '../views/mobile/index')
  },
  {
    path: '/mobile/:secondId/:navId',
    name: 'Mobile',
    component: () => import(/* webpackChunkName: "about" */ '../views/mobile/index')
  },
  {
    path: '/mobile/:secondId',
    name: 'Mobile',
    component: () => import(/* webpackChunkName: "about" */ '../views/mobile/index')
  },
  {
    path: '/pc/:secondId/:navId',
    name: 'content',
    component: () => import(/* webpackChunkName: "about" */ '../views/content/index')
  },
  {
    path: '/pc/:secondId',
    name: 'content',
    component: () => import(/* webpackChunkName: "about" */ '../views/content/index')
  },
  {
    path: '*',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/other')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
