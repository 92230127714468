import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import i18n from './i18n/i18n'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import en from 'element-ui/lib/locale/lang/en'
import cn from 'element-ui/lib/locale/lang/zh-CN'

//要添加的代码
router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})

i18n.mergeLocaleMessage('en', en)
i18n.mergeLocaleMessage('zh', cn)

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    isEnglish: false,
    menuList :{},
  },
  mutations: {
    increment (state) {
      state.count++
    }
  },
  computed:{
    setEnglish(isEnglish){
      this.store.isEnglish = isEnglish
    },
  },
  getters:{
    getEnglish(){
      return this.store.isEnglish
    }
  }
})



new Vue({
  i18n,
  router,
  store: store,
  render: h => h(App)
}).$mount('#app')
