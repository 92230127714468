/**
 * @author: Clay
 * @date: 2022/1/16 11:13
 * @description：sist
 * @email: clay@hchyun.com
 */
let vm = null

import {info} from "@/api";

export function setThis(that) {
  vm = that
}

export function getParentNode(id) {
  try {
    let menuList = JSON.parse(sessionStorage.getItem("menu"));
    for (let menu of menuList) {
      for (let child of menu.children) {
        if (id == child.encodeId) {
          menu.children = []
          child.children = []
          return {
            stair: menu, second: child,
          }
        }
        for (let item of child.children) {
          if (id == item.encodeId) {
            menu.children = []
            child.children = []
            item.children = []
            return {
              stair: menu, second: child, three: item
            }
          }
        }
      }
    }
    return null
  } catch (e) {
    return null
  }
}


export function getNodeMenu(id) {
  try {
    let menuList = JSON.parse(sessionStorage.getItem("menu"));
    for (let menu of menuList) {
      for (let child of menu.children) {
        for (let item of child.children) {
          if (id == item.encodeId) {
            return item
          }
        }
      }
    }
    return null
  } catch (e) {
    return null
  }
}

export function setAppLanguage() {
  let lang = sessionStorage.getItem("lang");
  if (lang != null) {
    vm.$i18n.locale = lang
    return
  }
  var language = window.navigator.userLanguage || window.navigator.language;
  if (language == 'zh-CN') {
    lang = 'cn'
  } else {
    lang = 'en'
  }
  vm.$i18n.locale = lang
  sessionStorage.setItem("lang", lang)
}

export function setLanguage() {
  let lang = vm.$route.query && vm.$route.query.lang
  if (lang != undefined) {
    vm.$i18n.locale = lang
    sessionStorage.setItem("lang", lang)
  }
}


export function toArticle(news, width) {
  console.log(width, "width")
  info(news.id).then(res => {
    let info = null
    try {
      info = getParentNode(res.data.encodeId)
      console.log(info)
    } catch (e) {
    }
    if (info == null) {
      return
    }
    let routeData = null;
    if (info.three == null) {
      if (width) {
        routeData = "/pc/" + info.second.encodeId + "?id=" + news.id + "&type=" + info.second.type + "&lang=" + sessionStorage.getItem("lang");
      } else {
        routeData = "/mobile/" + info.second.encodeId + "?id=" + news.id + "&type=" + info.second.type + "&lang=" + sessionStorage.getItem("lang");
      }
    } else {
      console.log(info.three, "toArticle,info.three")
      if (width) {
        routeData = "/pc/" + info.second.encodeId + "/" + info.three.encodeId + "?id=" + news.id + "&lang=" + sessionStorage.getItem("lang");
      } else {
        routeData = "/mobile/" + info.second.encodeId + "/" + info.three.encodeId + "?id=" + news.id + "&lang=" + sessionStorage.getItem("lang");
      }
    }
    linkTo(routeData)
  })
}

function linkTo(routeData) {
  let userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
  if (userAgent.indexOf("Safari") > -1) {
    console.log(routeData, "routeData", 2222)
    window.location.href = window.location.origin + routeData
  } else {
    console.log(routeData, "routeData")
    window.open(routeData, "_blank")
  }
}


export function lookMoreComment(item, width) {
  info(item.id).then(res => {
    let info = null
    try {
      info = getParentNode(res.data.encodeId)
    } catch (e) {
    }
    if (info == null) {
      return
    }
    if (info.three == null) {
      if (width) {
        vm.$router.push("/pc/" + info.second.encodeId + "?type=" + info.second.type + "&lang=" + sessionStorage.getItem("lang"));
      } else {
        vm.$router.push("/mobile/" + info.second.encodeId + "?type=" + info.second.type + "&lang=" + sessionStorage.getItem("lang"));
      }
    } else {
      if (width) {
        vm.$router.push("/pc/" + info.second.encodeId + "/" + info.three.encodeId + "?type=" + info.second.type + "&lang=" + sessionStorage.getItem("lang"));
      } else {
        vm.$router.push("/mobile/" + info.second.encodeId + "/" + info.three.encodeId + "?type=" + info.second.type + "&lang=" + sessionStorage.getItem("lang"));
      }
    }
  })
}


export function lookMoreFixedComment(id, width) {
  let info = null
  try {
    info = getParentNode(id)
  } catch (e) {
  }
  if (info == null) {
    return
  }
  let routeData = null;
  if (info.three == null) {
    if (width) {
      routeData = "/pc/" + info.second.encodeId + "?type=" + info.second.type + "&lang=" + sessionStorage.getItem("lang");
    } else {
      routeData = "/mobile/" + info.second.encodeId + "?type=" + info.second.type + "&lang=" + sessionStorage.getItem("lang");
    }
  } else {
    if (width) {
      routeData = "/pc/" + info.second.encodeId + "/" + info.three.encodeId + "?type=" + info.second.type + "&lang=" + sessionStorage.getItem("lang");
    } else {
      routeData = "/mobile/" + info.second.encodeId + "/" + info.three.encodeId + "?type=" + info.second.type + "&lang=" + sessionStorage.getItem("lang");
    }
  }
  linkTo(routeData)
  // window.location.href = window.location.origin + routeData.href
  // window.open(window.location.origin + routeData.href, "_blank")
  // }
}






