<template>
  <div id="header_bgc" style="background: linear-gradient(180deg, #0A51AE 0%, rgba(25,84,167,0.9) 56%, rgba(21,82,167,0.7) 69%, rgba(16,80,168,0.4) 85%, rgba(11,81,173,0) 100%);">
  <div class="header" id="header">
    <!--导航部分-->
    <div class="bottom">
      <el-row>
        <div class="bottom-div" style="margin-left: 1rem;">
          <el-col v-show="width" :xs="1" :sm="1" :md="4" :lg="4" :xl="5">
            <div class="icon">
<!--              <div class="icont"><img src="@/assets/header/logo@2x.png" alt=""></div>-->
              <div class="icont" v-show="login_blue" ><img src="@/assets/header/logob.png" alt=""></div>
              <div class="icont" v-show="!login_blue"><img src="@/assets/header/logol.png" alt=""></div>

              <!--            <div class="iconb"><img src="@/assets/header/sy_logo2@2x.png" alt=""></div>-->
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="19" :xl="19">
            <div class="banner">
              <router-link to="/home" v-bind:style="{color:link_color}">
                <div class="link_text">
                  <div v-show="$i18n.locale  === 'cn'" class="link_text_title cn_title">首页</div>
                  <div v-show="$i18n.locale  === 'en'" class="link_text_title en_title">Home</div>
                </div>
              </router-link>
              <span v-for="(menu,index) in menuData" :key="index">
<!--                    v-if="menu.displayType == 1 ||($i18n.locale  === 'cn'&& menu.displayType == 3)||($i18n.locale  === 'en'&& menu.displayType == 2)">-->
              <div class="link_text" id="link_text" v-if="menu.displayType != 4" v-bind:style="{color:link_color}">
                <div class="link_text_title" >
                  <span  class="cn_title" v-if="menu.displayType == 3 || menu.displayType == 1"
                         v-show="$i18n.locale  === 'cn'">{{ menu.title }}</span>
                  <span class="en_title"v-if="menu.displayType == 2 || menu.displayType == 1"
                        v-show="$i18n.locale  === 'en'">{{ menu.englishTitle }}</span>
                  <div class="secondary">
                    <ul style="text-align:left;">
                    <span v-for="(item,i) in menu.children" :key="i" @click="menuCut(item)">
                    <li class="menu_list"
                        v-if="item.displayType != 4 && (item.displayType == 3 || item.displayType == 1)"
                        v-show="$i18n.locale  === 'cn'">
                      <div class="menu_suffix">
                        <div>
                          <span>{{ item.title }}</span>
                          <!--                          <span v-if="(item.displayType == 2 || item.displayType == 1)&&$i18n.locale  === 'en'" >{{ item.englishTitle }}</span>-->
                        </div>
                      </div>
                    </li>
                    <li class="menu_list"
                        v-if="item.displayType != 4&&(item.displayType == 2 || item.displayType == 1)"
                        v-show="$i18n.locale  === 'en'">
                      <div class="menu_suffix" v-if="">
                        <div>
                          <span>{{ item.englishTitle }}</span>
                        </div>
                      </div>
                    </li>
                    </span>
                  </ul>
                  </div>
                </div>
                <!--<div class="secondary" v-if="index == 5">-->
              </div>
            </span>
              <div class="iconfont" @click="login" v-show="login_blue"><img src="@/assets/header/denglu.png" alt=""></div>
              <div class="iconfont" @click="login" v-show="!login_blue"><img src="@/assets/header/denglu1.png" alt=""></div>
              <div class="iconfont" @click="switchLang" v-if="login_blue && $i18n.locale  == 'cn'"><img src="@/assets/header/en.png" alt=""></div>
              <div class="iconfont" @click="switchLang" v-if="!login_blue && $i18n.locale  == 'cn'"><img src="@/assets/header/en1.png" alt=""></div>
              <div class="iconfont" @click="switchLang" v-if="login_blue && $i18n.locale  == 'en'"><img src="@/assets/header/enz.png" alt=""></div>
              <div class="iconfont" @click="switchLang" v-if="!login_blue && $i18n.locale  == 'en'"><img src="@/assets/header/enzl.png" alt=""></div>
            </div>
          </el-col>
        </div>
      </el-row>
    </div>
  </div>
  </div>
</template>

<script>
import {getNavigation} from "@/api";

export default {
  name: "index",
  data() {
    return {
      english: false,
      width: document.documentElement.clientWidth >= 1200,
      pc: document.documentElement.clientWidth > 992,
      menuData: [],
      link_color:'#C5DCFE',
      login_blue:true,
      en_logo_blue:false,
      en_login_white:false,
      ch_logo_blue:true,
      ch_logo_white:false
    }
  },
  mounted() {
    let Head = document.getElementById('header')
    let header_bgc = document.getElementById('header_bgc')
    // let link_text = document.getElementById('link_text') 为啥不能更改

    window.onscroll = ()=>{
      // 获取滚动距离
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      if (top !== 0) {
        Head.style = "position: fixed;top: 0;background-color: #fff;"
        header_bgc.style= "position: fixed;top: 0;background-color: #fff;box-shadow: 0px 1px 3px 0px rgba(8,57,122,0.18);"
        this.link_color='#0A51AE'
        this.login_blue=false
        this.ch_logo_blue=false
        this.ch_logo_white=true
        // this.en_login_white=false
      }else if(top === 0){
        Head.style = "position: static;"
        header_bgc.style= "position: static;background: linear-gradient(180deg, #0A51AE 0%, rgba(25,84,167,0.9) 56%, rgba(21,82,167,0.7) 69%, rgba(16,80,168,0.4) 85%, rgba(11,81,173,0) 100%);"
        this.link_color='#C5DCFE'
        this.login_blue=true
        this.ch_logo_blue=true
        this.ch_logo_white=false
        // this.en_login_white=true
      }
    }
    const that = this
    window.onresize = () => {
      return (() => {
        //这里写要操作的函数
        window.screenWidth = document.body.clientWidth
        that.width = window.screenWidth >= 1200
      })()
    }
  },
  created() {
    if (this.pc) {
      getNavigation({}).then(res => {
        this.menuData = res.data
        sessionStorage.setItem("menu", JSON.stringify(res.data))
      })
    }
  },
  methods: {
    login() {
      let routeData = this.$router.resolve("/admin");
      window.open(routeData.href, "_blank")
    },
    menuCut(menu) {
      window.open("/pc/" + menu.encodeId + "?type=" + menu.type + "&lang=" + sessionStorage.getItem("lang"))
    },
    switchLang() {
      let lang = ''
      if (this.$i18n.locale === 'en') {
        lang = 'cn'
      } else {
        lang = 'en'
      }
      sessionStorage.setItem("lang", lang)
      let fullPath = this.$route.fullPath
      debugger
      window.location.replace(fullPath.substr(0, fullPath.length - 2) + lang)
    }
  }
}
</script>

<style scoped lang="scss">
.active{
  color: red;

}
.cn_title{
  padding: 0 0.7rem;
}
.en_title{
  padding: 0 0.7rem;
}
.iconfont {
  cursor: pointer;
  display: inline-block;
  line-height: 7.6rem;
  //line-height: 11rem;
  padding-top: .7rem;
  margin-left: .5rem;

  img {
    width: 2.5rem;
    height: 2.6rem;
  }
}
#header_bgc{
  width: 100%;
  height: 7.6rem;
  display: flex;
  justify-content: center;
  align-content: center;
}

.header {
  margin: 0 auto;
  width: 124rem;
  z-index: 9999;
  .bottom {
    height: 7.6rem;
    //height: 11rem;
    //background: rgba(25, 86, 188, 0.6);
    border-radius: 0rem 0rem 2rem 2rem;

    .icon {
      margin-left: 4rem;

      div {
        margin-top: 2.4rem;
        //margin-top: 4.4rem;
      }

      .icont {
        img {
          // todo logo有问题
            width: 16rem;
          //width: 15rem;
        }
      }

      .iconb {
        img {
          width: 8.9rem;
        }
      }
    }

    .banner {
      a {
        text-decoration: none;
        //color: #ffffff;
        //color: #C5DCFE;
      }

      width: 100%;
      height: 7.6rem;
      //height: 11rem;
      clear: left;
      margin-left: 3rem;

      .link_text {
        height: 100%;
        text-align: center;
        float: left;
        font-size: 1.6rem;
        font-family: "微软雅黑";
        font-weight: 400;

        //color: red!important;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
      }

    .link_text_title {
        position: relative;
      }

      .secondary {
        display: none;
        width: 25rem;
        position: absolute;
        //top: 6rem;
        background-color: transparent;
        text-align: left !important;
        margin-top: 1rem;
        //display: block;
        //width: 100%;
        //height: 5rem;
        //background-color: #1956BC;


        ul {
          background-color: #ffffff;
          display: inline-block;
          border-radius: 0 0 .5rem .5rem;

          li {
            margin-top: 1.2rem;
            height: 2rem;
            list-style: none;
            //border-bottom: .1rem solid gray;
            color: #666666;
            //display: flex;
            //align-items: center;
            //justify-content: center;
            //clear: left;

            //.menu_prefix{
            //  width: 1.2rem;
            //}
            .menu_suffix {

              margin-left: .6rem;
              display: inline-block;
              //width: 14.3rem;
              border-left: .2rem solid #ffffff;
              margin-right: .8rem;
              padding-left: .8rem;

              div {
                padding: 0.2rem .4rem;
              }
            }

            div {
              float: left;
            }
          }

          li:hover {
            //background-color: rgba(25, 86, 188, 0.6);
          }

          li:last-child {
            margin-bottom: 1.4rem;
            //border-bottom: none;

          }
        }
      }


      .link_text:hover {
        //.border{
        //  background-color: #fff;
        //}
        .secondary {
          display: block;
        }

        .link_text_title {
          //font-weight: bolder !important;
        }
      }

      .menu_list:hover {
        .menu_suffix {

          border-left: .2rem solid #1956BC;

          div {
            color: #0054B0;
            background-color: #C3D7EC;
            border-radius: 0.2rem;
          }
        }
      }

    }
  }
}

@media screen and (min-width: 1200px) {
  .banner {
    margin: 0 auto;
  }
}

@media screen and (max-width: 1200px) {
  .banner {
    margin: 0 !important;
  }
  .header{
    width: 90rem;
  }
}

@media screen and (max-width: 992px) {
  #header{
    display: none!important;
  }
  #header_bgc{
    display: none!important;
  }
}
</style>
