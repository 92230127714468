<template>
  <div class="footer">
    <el-row>
      <el-col :xs="1" :sm="2" :md="2" :lg="3" :xl="6">
        <div class="grid-content"></div>
      </el-col>
      <el-col :xs="23" :sm="22" :md="22" :lg="20" :xl="17">
        <el-row>
          <el-col :span="12">
            <div class="fLeft" style="margin-top: 1rem">
              <p style="font-family:Verdana, Geneva, sans-serif;font-weight:500;font-size:2rem; padding-bottom:6px; ">
                {{ $t('message.contact_us') }}
                <!--CONTACT US-->
              </p>
              <p style="margin-top: 6px;">
                {{ $t('message.adress') }}
                <!--犀浦校区地址：中国四川省成都市高新区西部园区西南交通大学 信息科学与技术学院-->
              </p>
              <p>
                {{ $t('message.code') }}
                <!--邮政编码：611756-->
              </p>
              <p>
                {{ $t('message.tel') }}
                <!--电话：+86 28 66366709(行政)&lt;!&ndash;；&nbsp;+86 28 66366741(学生工作组)&ndash;&gt;-->
              </p>
              <p style="margin-top: 8px;">
                {{ $t('message.copyright') }}
                <!--版权所有 © 2015 西南交通大学信息科学与技术学院. All rights reserved. <a href="#">&nbsp;意见反馈</a>-->
              </p>
            </div>
          </el-col>
          <el-col :span="12">
            <table class="fRight" cellpadding="0" cellspacing="0" border="0">
              <tbody>
              <tr>
                <td>
<!--                  <img style="width: 6.7rem;" src="http://sist.swjtu.edu.cn:80/zh/images/logo3.jpg">-->
                </td>
                <td><p><a href="http://www.swjtu.edu.cn/" target="_blank">
                  {{ $t('message.chinese_site') }}
                  <!--西南交通大学（中文）-->
                </a></p>
                  <p><a href="http://english.swjtu.edu.cn/public/default.aspx" target="_blank">
                    <!--西南交通大学（ENGLISH）-->
                    {{ $t('message.english_site') }}
                  </a></p>
                  <p><a href="http://fad.swjtu.edu.cn/public/Default.aspx" target="_blank">
                    <!--西南交通大学国际合作与交流处-->
                    {{ $t('message.affairs_office') }}
                  </a></p>
                  <p><a href="http://www.csc.edu.cn/" target="_blank">
                    <!--国家留学基金管理委员会-->
                    {{ $t('message.scholarship') }}
                  </a></p>
                  <p><a href="http://www.fmprc.gov.cn/" target="_blank">
                    <!--中华人民共和国外交部-->
                    {{ $t('message.foreign') }}
                  </a></p>
                  <p><a href="http://www.moe.edu.cn/" target="_blank">
                    <!--中华人民共和国教育部门户网站-->
                    {{ $t('message.education') }}
                  </a></p>
                  <p><a href="http://www.ieee.org/" target="_blank">
                    <!--电气和电子工程师协会(IEEE)-->
                    {{ $t('message.ieee') }}
                  </a></p>
                </td>
              </tr>
              </tbody>
            </table>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped lang="scss">
.footer {
  padding-top: 2rem;
  height: 20rem;
  background-color: rgb(107, 109, 108);
  color: #b2b2b2 !important;
  font-size: 1.2rem;

  a {
    color: #b2b2b2 !important;
    text-decoration: none;
  }

  .fLeft {
    text-decoration: none;
    margin-top: 1rem;

    p {
      margin-top: .5rem;
    }
  }

  .fRight {
    margin-top: 1rem;
    text-decoration: red;

    p {
      margin-left: 1rem;
      margin-top: .5rem;
    }
  }

}

</style>
