//en.js

import enLocale from 'element-ui/lib/locale/lang/en'

const en = {
  message: {
    'student': 'Student',
    'staff': 'Staff',
    'alumni': 'Alumni',
    'examine': 'Examine',
    'services_portal': 'Services Portal',
    'switch': '简体中文',
    'home': 'Home',
    'news_ceter': 'News Center',
    'more': 'More',
    'research': 'Research',
    'academic': 'Academic',
    'party': 'Party',
    'party_education': 'Party Education',
    'teaching_work': 'Teaching work',

    'data_information': 'DATA INFORMATION',
    'notice': 'Notice',
    'scientific': 'Scientific',
    'undergraduate_education': 'Undergraduate education',
    'postgraduate_education': 'Postgraduate education',
    'employees': 'Faculty Members',
    'number_students': 'Internal Students',
    'graduate_tutor': 'Tutors',
    'research_team': 'Scientific Research Team',
    'high_level_talents': 'Outstanding Talents',
    'major_projects': 'Major projects',
    'contact_us': '',
    'person': '',
    'pics': '',
    'adress': '',
    'code': '',
    'tel': '',
    'copyright': 'Copyright © 2014 School of Information Science and Technology, SWJTU. All rights reserved.   Feedback',
    'chinese_site': 'Southwest Jiaotong University(中文)',
    'english_site': 'Southwest Jiaotong University(ENGLISH)',
    'affairs_office': 'SWJTU International Affairs Office',
    'scholarship': 'China Scholarship Counsil',
    'foreign': 'Ministry of Foreign Affairs',
    'education': 'Ministry of Education',
    'ieee': 'IEEE',
    'search': 'search',
    'placeholder': 'please input',

  },
}

export default en
